<template>
  <main class="page-container" id="sme-community-sub">


    <div v-if="!isMobile" class="community-sub-header gachi-editor-header">
      <div class="community-header-background">
        <div class="background-blur">
          <img src="../../../assets/img/community/profile.jpg" alt="" />
        </div>
      </div>
    </div>

    <div class="community-sub-body page-component">
      <span class="visually-hidden">게시글 카테고리</span>
      <div class="list-header">
        <div class="header-column">
          <div class="write-category">
            <select v-model="params.comunCtgrDcd" class="form-select" required="">
              <option value="1007001" selected="">판매해요</option>
              <option value="1007002">필요해요</option>
              <option value="1007003">궁금해요</option>
              <option value="1007004">함께해요</option>
            </select>
          </div>
        </div>
      </div>

      <span class="visually-hidden">게시글 제목</span>
      <div class="form-row mb-5">
        <div class="form-cellth">
          <label>제목</label>
        </div>
        <div class="form-celltd">
          <div class="form-row">
            <input v-model="params.comunBltartclTit" type="text" class="form-control" placeholder="게시글 제목">
          </div>
        </div>
      </div>

      <span class="visually-hidden">게시글 본문 시작</span>
      <div id="gachi-editor-container" class="editor-area">
        <!-- 여기서부터는 실제 에디터 스타일을 변경하여 구현 -->
        <ckeditor v-model="params.comunBltartclCn" :config="editorConfig" @namespaceloaded="onNamespaceLoaded" @ready="onReady"></ckeditor>
      </div>

      <div class="page-buttons" data-align="space-between">
        <div class="button-column">
          <loading-button
              :button-text="action"
              :is-loading="isLoading"
              :click-func="clickSave"
              :button-classes="['btn-lg', 'btn-outline-white']"
              :text-classes="['text-secondary']"
              :style-obj="{'margin-right': '5px'}"
              loading-size="sm"
          ></loading-button>

          <loading-button
              v-if="comunBltartclId > 0"
              button-text="삭제"
              :is-loading="isRmLoading"
              :click-func="clickRemove"
              :button-classes="['btn-lg', 'btn-secondary']"
              :text-classes="[]"
              loading-size="sm"
          ></loading-button>
<!--          <button class="btn btn-lg btn-secondary" style="margin-left: 5px">-->
<!--            <span class="text">삭제</span>-->
<!--          </button>-->
        </div>
        <div class="button-column">
          <button class="btn btn-lg btn-primary" @click="goList">
            <span class="text">목록</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import {mapGetters} from 'vuex';
import {slideImage, slideImageInit} from '../../../assets/js/ckeditor4/ckeditor-slide-image';
import {gachiConfig} from '../../../assets/js/ckeditor4/ckeditor-config';
import embed from '../../../assets/js/ckeditor4/embed';
import cloudservice from '../../../assets/js/ckeditor4/cloudservice';
import easyimage from '../../../assets/js/ckeditor4/easyimage';
import {
  ACT_DELETE_SB_COMMUNITY,
  ACT_GET_SB_COMMUNITY,
  ACT_INSERT_SB_COMMUNITY,
  ACT_UPDATE_SB_COMMUNITY,
} from '../../../store/_act_consts';
import {getItem, getResult, isSuccess, lengthCheck, validate} from '../../../assets/js/utils';
import LoadingButton from '../../../components/common/LoadingButton';
import {MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../../store/_mut_consts';
import cateEmbed from '../../../assets/js/ckeditor4/cate-embed';
import nasEmbed from '../../../assets/js/ckeditor4/nas-embed';

export default {
  name: 'CommunityEdit',
  components: {
    LoadingButton
  },
  data: () => ({
    comunBltartclId: 0,
    params: {
      comunCtgrDcd: '1007001',
      comunBltartclTit: '',
      comunBltartclCn: '',
      thmbnlImgPtUrl: '',
    },
    instances: null,
    editorConfig: gachiConfig,
    imgSrcRex: /<img.*?src="(.*?)"[^>]+>/g,
    isLoading: false,
    isRmLoading: false
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('auth', ['isAuth', 'session']),
    action(){
      return this.comunBltartclId > 0 ? '수정' : '등록';
    }
  },
  created() {
    if(this.$route.params.comunBltartclId) this.comunBltartclId = this.$route.params.comunBltartclId;
    // if(this.comunBltartclId) {
    //   this.getSbCommunity();
    // }
  },
  methods: {
    async onReady(instances){
      this.instances = instances;
      if(this.comunBltartclId > 0) {
        this.getSbCommunity(instances.document.$);
      }
    },
    onNamespaceLoaded( CKEDITOR ) {
      embed(CKEDITOR);
      cloudservice(CKEDITOR);
      easyimage(CKEDITOR);
      slideImage(CKEDITOR);
      cateEmbed(CKEDITOR);
      nasEmbed(CKEDITOR);
    },
    goList(){
      this.$router.push({name: 'Community'});
    },
    getSbCommunity($document){
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY}`, {comunBltartclId: this.comunBltartclId}).then(res => {
        if(lengthCheck(res)){
          const item = getItem(res);
          Object.keys(this.params).forEach(key => {
            if(item[key] !== null && item[key] !== undefined){
              this.params[key] = item[key];
            }
          });
          setTimeout(() => {
            slideImageInit($document);
          }, 500)

        }else{
          this.comunBltartclId = 0;
        }
      }).catch(e => {
        console.error(e);
        this.comunBltartclId = 0;
      });
    },

    async clickSave(){
      if(this.isLoading) return;
      this.isLoading = true;
      if(
          validate(this.$store, this.params, [
            {key: 'comunCtgrDcd', msg: '카테고리를 선택해주세요.'},
            {key: 'comunBltartclTit', msg: '커뮤니티 게시글 제목을 입력해주세요.'},
          ])
      ){
        await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          title: `커뮤니티 게시글을 ${this.action}하시겠습니까?`,
          showCancelButton: true,
          yesfunc: this.saveSbCommunity,
          nofunc: () => {this.isLoading = false;},
        });
      }else{
        this.isLoading = false;
      }
    },
    async saveSbCommunity(){
      try {
        this.minifyCn();
        const res = this.comunBltartclId > 0 ?
            await this.$store.dispatch(`community/${ACT_UPDATE_SB_COMMUNITY}`, {comunBltartclId: this.comunBltartclId, params: this.params}) :
            await this.$store.dispatch(`community/${ACT_INSERT_SB_COMMUNITY}`, this.params);

        if(isSuccess(res)){
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `커뮤니티 게시글이 ${this.action}되었습니다.`,
            yesfunc: this.goList,
            rightNow: true
          });
        }else{
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        }
        this.isLoading = false;
      } catch (e) {
        console.error(e);
        await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isLoading = false;
      }
    },
    clickRemove(){
      if(this.isRmLoading) return;
      this.isRmLoading = true;

      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '게시글을 삭제하시겠습니까?',
        // text: '삭제된 캠페인은 원상복구 할 수 없습니다.',
        showCancelButton: true,
        yesfunc: this.deleteSbCommunity,
        nofunc: () => {this.isRmLoading = false;}
      });
    },
    deleteSbCommunity(){
      this.$store.dispatch(`community/${ACT_DELETE_SB_COMMUNITY}`, this.comunBltartclId).then(res => {
        this.isRmLoading = false;
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `게시글이 삭제되었습니다.`,
            yesfunc: this.goList,
            rightNow: true
          });
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      }).catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
        this.isRmLoading = false;
      })
    },
    minifyCn(){
      if(this.instances != null){
        const editorContents = this.instances.getData();
        if(editorContents !== null && editorContents !== undefined){
          const m = this.imgSrcRex.exec( editorContents );
          if(m) this.params.thmbnlImgPtUrl = m[1];
          this.params.comunBltartclCn = editorContents.replace(/^\s+|\r\n|\n|\r|(>)\s+(<)|\s+$/gm, '$1$2');
        }
      }
    },
  }
}
</script>
